import React from "react"

export default function ChipNotificationHeroComponent() {
  return (
    <div className="flex items-center justify-center">
      {/*<!-- Component: Elevated chip notification with leading button and trailing link --> */}
      <div className="inline-flex items-center gap-4 rounded border border-slate-200 bg-white/50 p-0.5 pr-4 shadow-sm shadow-slate-700/10">
        <button className="inline-flex h-8 items-center justify-center gap-2 whitespace-nowrap rounded bg-emerald-500 px-4 text-xs font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
          <span>version 3.40</span>
        </button>
        <div className="flex items-center gap-1 text-slate-500">
          <span className="hidden md:block">With 20 new components!</span>
          <a
            href="javascript:void(0)"
            className="inline-flex items-center justify-center gap-2 justify-self-center whitespace-nowrap tracking-wide text-emerald-500 transition duration-300 hover:text-emerald-600 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-emerald-300 disabled:shadow-none"
          >
            <span>Check what's new</span>
            <span className="relative only:-mx-6">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-5 w-5"
                aria-label="Arrow icon"
                role="graphics-symbol"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
                />
              </svg>
            </span>
          </a>
        </div>
      </div>
      {/*<!-- End Elevated chip notification with leading button and trailing link --> */}
    </div>
  )
}
